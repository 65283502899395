import { ThreadMessageLike } from '@assistant-ui/react';
import { QueryFunctionContext } from '@tanstack/react-query';

import { request } from '@request';

import { AIAgentQuestions, AIAgentThreadHistory } from '../types';
import { URL_API_BASE } from './const';

export function convertAIAgentThreadHistoryToThreadMessages(history: AIAgentThreadHistory | undefined): ThreadMessageLike[] {
  if (!history) {
    return [];
  }
  return Object.values(history.chatHistory)
    .filter((message) => !!message.messageText)
    .map((message) => ({
      role: message.from === 'AI Assistant' ? 'assistant' : 'user',
      content: message.messageText as string,
      id: message.uuid,
      createdAt: new Date(message.timestamp),
      metadata: {
        custom: {
          chatThreadUuid: message.chatThreadUuid,
          userUuid: message.userUuid
        }
      }
    }));
}

export async function getAIAgentQuestions(uuid: string, { signal }: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/ai-agent/questions?userUuid=${uuid}`, { signal });
  return res.data as AIAgentQuestions;
}

export async function aiAgentUpdateQA(data: AIAgentQuestions) {
  const res = await request.put(`${URL_API_BASE}/ai-agent/questions`, { data });
  return res.data as AIAgentQuestions;
}

export async function getAiAgentThreadHistory(uuid: string, { signal }: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/ai-agent/thread?userUuid=${uuid}`, { signal });
  return res.data as AIAgentThreadHistory;
}
