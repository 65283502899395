import * as z from 'zod';

import { LinkedInURLValidationSchema, LocationValidationSchema, PasswordValidationSchema, UserNameValidationSchema } from './common';
import { getMsgMin } from './common-messages';

export const RegisterFormValidationSchemaObject = z.object({
  username: UserNameValidationSchema,
  firstName: z.string().min(1, getMsgMin('Name', 1)).max(2147483647),
  lastName: z.string().min(1, getMsgMin('Surname', 1)).max(2147483647),
  linkedInUrl: LinkedInURLValidationSchema,
  password: PasswordValidationSchema,
  confirmPassword: PasswordValidationSchema,
  jobPosition: z.string().min(2).max(2147483647),
  location: LocationValidationSchema,
  howDidYouHearAboutUs: z.string().max(2147483647).optional().or(z.literal('')).or(z.null()),
  acceptTermsAndConditions: z.literal(true, { errorMap: () => ({ message: 'You must accept the terms and conditions' }) })
});

export const RegisterFormValidationSchema = RegisterFormValidationSchemaObject.refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ['confirmPassword']
});

export type RegisterFormValidationSchemaType = z.infer<typeof RegisterFormValidationSchema>;
