import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getAIAgentQuestions, getAiAgentThreadHistory } from '../query-fn';

export const AIAgentQueryStore = createQueryKeys('ai-agent', {
  questions: (uuid: string) => ({
    queryKey: [uuid],
    queryFn: (ctx) => getAIAgentQuestions(uuid, ctx)
  }),
  'thread-history': (uuid: string) => ({
    queryKey: [uuid],
    queryFn: (ctx) => getAiAgentThreadHistory(uuid, ctx)
  })
});
